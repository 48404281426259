import { Box } from "@mui/material";
import "swiper/css/pagination";
import Footer from "../components/landing/Footer";
import LandingHeader from "../components/landing/LandingHeader";
import { Outlet } from "react-router-dom";

const LandingPage = () => {
  return (
    <Box className={"relative"}>
      <LandingHeader />
      <Box className="bg-black text-white">
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};

export default LandingPage;
