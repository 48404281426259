import { Control, Controller, FieldErrors } from "react-hook-form";
import { Stack, TextField } from "@mui/material";
import * as React from "react";
import { RequestMovieInputs } from "../../pages/RequestMoviePage";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "react-i18next";

interface RequestMovieStepProps {
  control: Control<RequestMovieInputs, any> | undefined;
  errors: FieldErrors<RequestMovieInputs>;
}

const RequestMovieStepTwo = ({ control, errors }: RequestMovieStepProps) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Controller
        name={"title"}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={fieldState.invalid}
            helperText={errors.title?.message}
            required
            fullWidth
            autoComplete={"title"}
            variant="standard"
            label={t("Title")}
          />
        )}
      />
      <Controller
        name={"link"}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={fieldState.invalid}
            helperText={errors.link?.message}
            fullWidth
            autoComplete={"link"}
            variant="standard"
            label={t("Link to the movie (if you have)")}
          />
        )}
      />
      <Controller
        name={"request"}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={fieldState.invalid}
            helperText={errors.request?.message}
            fullWidth
            multiline
            rows={4}
            autoComplete={"request"}
            variant="standard"
            label={t("Message")}
          />
        )}
      />
      <Controller
        name={"policy"}
        control={control}
        render={({ field, fieldState }) => (
          <FormControl
            required
            error={fieldState.invalid}
            component="fieldset"
            sx={{ m: 3 }}
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={<Checkbox {...field} />}
                label={t("Recaptcha")}
              />
            </FormGroup>
            <FormHelperText>{errors.policy?.message}</FormHelperText>
          </FormControl>
        )}
      />
    </Stack>
  );
};

export default RequestMovieStepTwo;
