import { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../components/layout/Header";
import Sidebar from "../components/layout/Sidebar";
import { DrawerContext } from "../context/drawerContext";
import { Outlet } from "react-router-dom";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import { useAppSelector } from "../store/hooks";
import { selectColorMode } from "../store/settings/settingSlice";
import { Theme } from "../utils/enum";

export const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
];

const Dashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const mode = useAppSelector(selectColorMode);

  const toggleDrawer = (): void => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerState = {
    width: 240,
    isOpen: drawerOpen,
    toggleDrawer,
  };

  return (
    <DrawerContext.Provider value={drawerState}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header />
        <Sidebar />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              mode === Theme.Light ? theme.palette.grey[100] : "#121212",
            flexGrow: 1,
            height: "100vh",
            minHeight: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
            <Outlet />
          </Container>
        </Box>
      </Box>
    </DrawerContext.Provider>
  );
};

export default Dashboard;
