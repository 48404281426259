interface IAllyProps {
  id: string;
  "aria-controls": string;
}

const a11yProps = (index: number): IAllyProps => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export default a11yProps;
