import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";

const StyledUnderBannerCarousel = styled(Box)<BoxProps>(({ theme }) => ({
  marginBottom: "10rem",
  [theme.breakpoints.up("sm")]: {
    marginBottom: "15rem",
  },
  [theme.breakpoints.up("md")]: {
    marginBottom: "20rem",
  },
}));

export default StyledUnderBannerCarousel;
