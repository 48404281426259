import { Banner } from "../store/movie/movieSlice";
import qs from "qs";
import axios from "axios";

interface IActorAttributes {
  name: string;
  bod: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  bio?: any;
  slug?: any;
  avatar: Banner;
}

export interface IActor {
  id: number;
  attributes: IActorAttributes;
}

export interface ActorsResponse {
  data: IActor[];
}

export const fetchActors = async (): Promise<ActorsResponse> => {
  const query = qs.stringify(
    {
      populate: ["avatar"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  return await axios
    .get<ActorsResponse>(`${process.env.REACT_APP_API_URL}/actors?${query}`)
    .then((res) => res.data);
};
