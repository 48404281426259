import { useSelector } from "react-redux";
import { AuthState, User } from "../store/auth/authSlice";
import { RootState } from "../store/store";

const useAuth = (): AuthState => {
  const user = useSelector((state: RootState): User | null => state.auth.user);
  const jwt = useSelector((state: RootState): string | null => state.auth.jwt);

  return { user, jwt };
};

export default useAuth;
