import { Control, Controller, FieldErrors } from "react-hook-form";
import { Stack, TextField } from "@mui/material";
import * as React from "react";
import { RequestMovieInputs } from "../../pages/RequestMoviePage";
import { useTranslation } from "react-i18next";

interface RequestMovieStepProps {
  control: Control<RequestMovieInputs, any> | undefined;
  errors: FieldErrors<RequestMovieInputs>;
}

const RequestMovieStepOne = ({ control, errors }: RequestMovieStepProps) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={2}>
      <Controller
        name={"firstName"}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={fieldState.invalid}
            helperText={errors.firstName?.message}
            required
            autoComplete={"firstName"}
            variant="standard"
            label={t("First Name")}
          />
        )}
      />
      <Controller
        name={"lastName"}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={fieldState.invalid}
            helperText={errors.lastName?.message}
            required
            autoComplete={"lastName"}
            variant="standard"
            label={t("Last Name")}
          />
        )}
      />
      <Controller
        name={"email"}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={fieldState.invalid}
            helperText={errors.email?.message}
            required
            fullWidth
            type="email"
            variant="standard"
            label={t("Email")}
          />
        )}
      />
    </Stack>
  );
};

export default RequestMovieStepOne;
