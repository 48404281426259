import { RootState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PhaseResponse } from "../../api/phases";
import { ActorsResponse } from "../../api/actors";

// TODO: Duplicate code in `Small`, `Medium`, `Thumbnail`
export interface FormatAttributes {
  ext: string;
  url: string;
  hash: string;
  mime: string;
  name: string;
  path?: never;
  size: number;
  width: number;
  height: number;
}

export interface Formats {
  small: FormatAttributes;
  medium: FormatAttributes;
  thumbnail: FormatAttributes;
}

export interface Attributes2 {
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: Formats;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: never;
  provider: string;
  provider_metadata?: never;
  createdAt: Date;
  updatedAt: Date;
}

export interface BannerData {
  id: number;
  attributes: Attributes2;
}

export interface Banner {
  data: BannerData;
  url?: string;
  id?: number;
}

export interface MovieAttributes {
  id?: number;
  title: string;
  description: string;
  playerUrl: string;
  imdbId: string;
  releaseDate: string;
  view?: never;
  trailer: string;
  duration: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  timeline: number;
  locale: string;
  slug: string;
  hls?: never;
  banner: Banner;
  poster: Banner;
  actors?: ActorsResponse;
  phase?: PhaseResponse;
}

export interface IMovie {
  id: number;
  attributes: MovieAttributes;
}

export interface MovieResponse {
  data: IMovie;
}

export interface MoviesResponse {
  data: IMovie[];
}

interface MovieState {
  movies: IMovie[];
}

const initialState: MovieState = {
  movies: [],
};

const movieSlice = createSlice({
  name: "movie",
  initialState,
  reducers: {
    setMovies(state, action: PayloadAction<IMovie[]>) {
      state.movies = action.payload;
    },
  },
});

export const { setMovies } = movieSlice.actions;

export default movieSlice.reducer;

export const selectMovies = (state: RootState): IMovie[] => state.movie.movies;
