import { useQuery } from "@tanstack/react-query";
import { fetchMovies } from "../../api/movies";
import Container from "@mui/material/Container";
import { Box, Grid } from "@mui/material";
import SmallMovieCard from "../movie/SmallMovieCard";
import { Direction } from "../../utils/enum";

const FilterList = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["movies"],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: async () => await fetchMovies(),
  });

  return (
    <Box className="bg-black">
      <Container maxWidth="lg" className="p-20 pt-32">
        {isLoading && <div>Loading...</div>}
        {isError && <div>Error!</div>}
        <Grid
          container
          spacing={{ xs: 2, md: 8 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {data &&
            data.data.map((movie) => (
              <Grid item xs={12} sm={6} md={3} key={movie.id}>
                <SmallMovieCard direction={Direction.Horizontal} data={movie} />
              </Grid>
            ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FilterList;
