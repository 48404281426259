import { useContext } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, CircularProgress, IconButton, Toolbar } from "@mui/material";

import { DrawerContext } from "../../context/drawerContext";
import useSnackBar from "../../hooks/useSnackBar";
import AppBar from "./AppBar";
import PhaseList from "../../common/PhaseList";
import { useGetPhasesQuery } from "../../api/rtkQuery";
import { useTranslation } from "react-i18next";
import ProfileMenu from "../../common/ProfileMenu";
import { Severity } from "../../utils/enum";

const Header = () => {
  const { t } = useTranslation();
  const { openSnackBar } = useSnackBar();
  const { width: drawerWidth } = useContext(DrawerContext);
  const { data, isLoading, isError } = useGetPhasesQuery(null);

  if (isError) {
    openSnackBar(t("Can't load phases"), Severity.Error);
  }

  return (
    <DrawerContext.Consumer>
      {({ isOpen, toggleDrawer }) => (
        <>
          <AppBar position="absolute" open={isOpen} drawerWidth={drawerWidth}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(isOpen && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }}>
                {data !== undefined ? (
                  <PhaseList isLoading={isLoading} phases={data} />
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </Box>
                )}
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <ProfileMenu />
              </Box>
            </Toolbar>
          </AppBar>
        </>
      )}
    </DrawerContext.Consumer>
  );
};

export default Header;
