import { Swiper, SwiperSlide } from "swiper/react";
import { useQuery } from "@tanstack/react-query";
import { fetchMovies } from "../../api/movies";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import StyledCarousel from "./StyledCarousel";
import SmallMovieCard from "../movie/SmallMovieCard";
import { Direction } from "../../utils/enum";

interface MovieCarouselProps {
  underBanner?: boolean;
  title: string;
  direction: Direction.Horizontal | Direction.Vertical;
}

const MovieCarousel = ({
  underBanner,
  title,
  direction,
}: MovieCarouselProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const [sliderPerView, setSliderPerView] = useState(2.5);
  const { data: movies } = useQuery({
    queryKey: ["movies"],
    refetchOnWindowFocus: false,
    queryFn: async () => await fetchMovies(),
  });

  useEffect(() => {
    if (isMobile) {
      setSliderPerView(2.5);
    }
    if (isTablet) {
      setSliderPerView(3.5);
    }
    if (isDesktop) {
      setSliderPerView(4.5);
    }
    if (isLargeDesktop) {
      setSliderPerView(6.5);
    }
    //eslint-disable-next-line
  }, [isMobile, isTablet, isDesktop]);

  return (
    <StyledCarousel
      className="w-full inset-x-0 bg-black"
      underBanner={underBanner}
    >
      <Typography
        variant="h3"
        gutterBottom
        className="relative z-10 text-white"
        sx={{ fontWeight: 700, textAlign: "left" }}
      >
        {t(title)}
      </Typography>
      <Swiper
        slidesPerView={sliderPerView}
        spaceBetween={10}
        className="smallSwiper"
      >
        {movies?.data
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)
          .map((movie) => (
            <SwiperSlide key={movie.attributes.title}>
              <SmallMovieCard direction={direction} data={movie} />
            </SwiperSlide>
          ))}
      </Swiper>
    </StyledCarousel>
  );
};

export default MovieCarousel;
