import qs from "qs";
import axios from "axios";
import { Banner } from "../store/movie/movieSlice";

export interface IPhaseAttributes {
  name: string;
  number: number;
  description: string | null;
  createAt: string;
  updatedAt: string;
  publishedAt: string;
  banner: Banner;
}

export interface IPhase {
  id: number;
  attributes: IPhaseAttributes;
}

export interface PhaseResponse {
  data: IPhase;
}

export interface PhasesResponse {
  data: IPhase[];
}

export const fetchPhases = async (): Promise<PhasesResponse> => {
  const query = qs.stringify(
    {
      populate: ["banner"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  return await axios
    .get<PhasesResponse>(`${process.env.REACT_APP_API_URL}/phases?${query}`)
    .then((res) => res.data);
};
