import * as React from "react";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import GlobalSnackBar from "./common/GlobalSnackBar";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { AuthState, setCredentials } from "./store/auth/authSlice";
import { ROUTE_MOVIES } from "./utils/route";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { selectColorMode } from "./store/settings/settingSlice";
import { Constant } from "./utils/enum";

const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = new QueryClient();
  const mode = useAppSelector(selectColorMode);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  useEffect(() => {
    const user = localStorage.getItem(Constant.User);
    if (user) {
      const parsedUser = JSON.parse(user) as AuthState;
      dispatch(setCredentials(parsedUser));
      navigate(ROUTE_MOVIES);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalSnackBar />
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Outlet />
          </QueryClientProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
};

export default App;
