import { RootState } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPhase {
  id: number;
  name: string;
  phase: number;
}

interface PhaseState {
  phases: IPhase[];
}

interface PhaseAttribute {
  name: string;
  number: number;
  description: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
}

interface PhaseItemResponse {
  id: number;
  attributes: PhaseAttribute;
}

export interface PhaseResponse {
  data: PhaseItemResponse[];
}

const initialState: PhaseState = {
  phases: [],
};

const phaseSlice = createSlice({
  name: "phase",
  initialState,
  reducers: {
    setPhases(state, action: PayloadAction<IPhase[]>) {
      state.phases = action.payload;
    },
  },
});

export const { setPhases } = phaseSlice.actions;

export default phaseSlice.reducer;

export const selectPhases = (state: RootState): IPhase[] => state.phase.phases;
