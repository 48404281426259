import { Alert, Avatar, Paper, Typography } from "@mui/material";
import Loading from "../../common/Loading";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getFavorites, updateFavorites } from "../../api/favorites";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { isValueDefined } from "../../utils/typeguard";
import { YouTube } from "@mui/icons-material";
import { friendlyDuration } from "../../utils/time";
import Link from "@mui/material/Link";
import { ROUTE_MOVIES } from "../../utils/route";
import { Severity } from "../../utils/enum";

export interface IUpdateFavorites {
  userId: number;
  movieIds: number[];
}

const Favorites = () => {
  const { t } = useTranslation();
  // const { data, isLoading, isFetching, isError } = useGetMoviesQuery(null);
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ["favorites"],
    refetchOnWindowFocus: false,
    queryFn: async () => await getFavorites(),
  });
  const [updateData, setUpdateData] = useState<IUpdateFavorites>({
    userId: user?.id ?? 0,
    movieIds: [],
  });
  const profileMutation = useMutation({
    mutationFn: updateFavorites,
  });

  const columns: GridColDef[] = [
    {
      field: "banner",
      headerName: "Poster",
      width: 70,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Avatar alt={params.row.banner.url} src={params.row.banner.url} />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      width: 340,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            href={`${ROUTE_MOVIES}/${params.row.slug}`}
            underline="hover"
            color="inherit"
          >
            {params.row.title}
          </Link>
        );
      },
    },
    {
      field: "releaseDate",
      headerName: "Release Date",
      width: 130,
      renderCell: (params: GridRenderCellParams) => (
        <Typography>
          {new Date(params.row.releaseDate).toLocaleDateString()}
        </Typography>
      ),
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 130,
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{friendlyDuration(params.row.duration)}</Typography>
      ),
    },
    {
      field: "trailer",
      headerName: "Trailer",
      width: 130,
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <Link
          underline={"none"}
          color={"red"}
          href={params.row.trailer}
          target="_blank"
        >
          <YouTube fontSize="large">{t("View")}</YouTube>
        </Link>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params: GridRenderCellParams) => (
        <Button variant="text" onClick={() => onDelete(params.row.id)}>
          {t("Delete")}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    /* TODO: How about:
    * const result = data?.filter((movie) => {
        return movie.id;
      });
    * */
    const movieIds = data?.map((movie) => movie.id);
    const result = movieIds ? movieIds.filter(isValueDefined) : [];

    setUpdateData({
      userId: user?.id ?? 0,
      movieIds: result,
    });
  }, [data, user]);

  if (isError) return <div>{t("An error has occurred!")}</div>;

  if (isLoading || isFetching) {
    return <Loading />;

    // test the error boundary
    // throw new Error("Crashed!");
  }

  const onUpdate = (): void => {
    profileMutation.mutate(updateData);
  };

  const onDelete = (id: number): void => {
    const removedData = data?.filter((movie) => movie.id !== id);
    queryClient.setQueryData(["favorites"], removedData);
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        {t("Favorites list")}
      </Typography>
      <Paper sx={{ width: "100%" }}>
        {data !== undefined ? (
          <DataGrid
            autoHeight
            rows={data}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        ) : (
          <Alert severity={Severity.Error}>
            {t("Favorites list is empty!")}
          </Alert>
        )}
      </Paper>
      <Box sx={{ pt: 4, display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" onClick={onUpdate}>
          {t("Save")}
        </Button>
      </Box>
    </Box>
  );
};

export default Favorites;
