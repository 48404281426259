import Link from "@mui/material/Link";
import { Typography } from "@mui/material";

interface RenderLinkProps {
  value: string;
}

const RenderLink = ({ value }: RenderLinkProps) => {
  if (value && value.toString().includes("http")) {
    return (
      <Link underline="none" href={value}>
        {value}
      </Link>
    );
  }

  return <Typography>{value}</Typography>;
};

export default RenderLink;
