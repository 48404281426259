import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { MovieAttributes } from "../movie/movieSlice";

export interface Role {
  id: number;
  name: string;
  description: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface User {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
  gender: string;
  favorites?: MovieAttributes[];
  role?: Role;
}

export interface UserResponse {
  user: User;
  jwt: string;
}

export interface SignInRequest {
  identifier: string;
  password: string;
}

export interface SignUpRequest {
  username: string;
  email: string;
  password: string;
}

export interface AuthState {
  user: User | null;
  jwt: string | null;
}

const initialState: AuthState = {
  user: null,
  jwt: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials(
      state,
      { payload: { user, jwt } }: PayloadAction<AuthState>
    ) {
      state.user = user;
      state.jwt = jwt;
    },
    clearCredentials(state) {
      state.user = null;
      state.jwt = null;
    },
  },
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;
