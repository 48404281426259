import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface LandingState {
  isFiltering: boolean;
}

const initialState: LandingState = {
  isFiltering: false,
};

export const landingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    setIsFiltering(state, action: PayloadAction<boolean>) {
      state.isFiltering = action.payload;
    },
  },
});

export const { setIsFiltering } = landingSlice.actions;

export const selectIsFiltering = (state: RootState): boolean =>
  state.landing.isFiltering;

export default landingSlice.reducer;
