import { Box, Button, IconButton, MenuItem, Typography } from "@mui/material";
import ProfileMenu from "../../common/ProfileMenu";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchPhases } from "../../api/phases";
import { useTranslation } from "react-i18next";
import { fetchMovies, filterMovies } from "../../api/movies";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import { useAppDispatch } from "../../store/hooks";
import { setIsFiltering } from "../../store/landing/landingSlice";
import { activeClassName, navLinkClassName } from "../../common/PhaseList";

const LandingHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [selectedPhase, setSelectedPhase] = useState<number | null>(null);
  const { data: phases } = useQuery({
    queryKey: ["phases"],
    refetchOnWindowFocus: false,
    queryFn: async () => await fetchPhases(),
  });

  const { refetch } = useQuery({
    queryKey: ["movies"],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: async () => await fetchMovies(),
  });

  const filterMutation = useMutation({
    mutationFn: filterMovies,
    onSuccess(data) {
      queryClient.setQueryData(["movies"], data);
    },
  });

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onPhaseClick = (phase: number) => {
    setSelectedPhase(phase);
    dispatch(setIsFiltering(true));

    if (phase === 0) {
      refetch().then(() => {});
      return;
    }

    filterMutation.mutate({
      filters: {
        phase: {
          number: {
            $eq: phase,
          },
        },
      },
    });
  };

  const onHomeClick = (): void => {
    dispatch(setIsFiltering(false));
    setSelectedPhase(null);
    filterMutation.reset();
    navigate("/");
  };

  return (
    <Box className="absolute z-10 top-0 w-full bg-gradient-to-b from-black px-4 lg:px-16 flex flex-row items-center max-h-[70px]">
      <Button onClick={onHomeClick}>
        <Typography
          variant="h3"
          color="white"
          sx={{
            fontWeight: 700,
          }}
        >
          FMOVIE
        </Typography>
      </Button>
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon sx={{ color: "white" }} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={!!anchorElNav}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <MenuItem key="all-timeline" onClick={() => onPhaseClick(0)}>
            <Typography textAlign="center">{t("All timelines")}</Typography>
          </MenuItem>
          {phases?.data.map((phase) => (
            <MenuItem key={phase.id} onClick={() => onPhaseClick(phase.id)}>
              <Typography textAlign="center">
                {t(phase.attributes.name)}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box
        className="ml-4"
        sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
      >
        <Button
          onClick={() => onPhaseClick(0)}
          key="all-timeline"
          className={selectedPhase === 0 ? activeClassName : navLinkClassName}
        >
          {t("All timelines")}
        </Button>
        {phases?.data.map((phase) => (
          <Button
            key={phase.attributes.name}
            className={
              phase.id === selectedPhase ? activeClassName : navLinkClassName
            }
            onClick={() => onPhaseClick(phase.id)}
          >
            {t(phase.attributes.name)}
          </Button>
        ))}
      </Box>
      <Box sx={{ flexGrow: 1 }}></Box>
      <ProfileMenu />
    </Box>
  );
};

export default LandingHeader;
