import useAuth from "../../hooks/useAuth";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTE_LOGIN } from "../../utils/route";

const RequireAuth = () => {
  const auth = useAuth();
  const location = useLocation();

  return auth.user != null ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTE_LOGIN} state={{ from: location }} />
  );
};

export default RequireAuth;
