import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";

const Loading = () => {
  return (
    <Container>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    </Container>
  );
};

export default Loading;
