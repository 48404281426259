import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Stack, TextField } from "@mui/material";
import { setCredentials } from "../../store/auth/authSlice";
import { useLoginMutation } from "../../api/rtkQuery";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSnackBar from "../../hooks/useSnackBar";
import {
  isErrorWithMessage,
  isFetchBaseQueryError,
} from "../../services/helper";
import LoadingButton from "@mui/lab/LoadingButton";
import { ROUTE_MOVIES } from "../../utils/route";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../utils/formSchema";
import { useTranslation } from "react-i18next";
import { Constant, Severity } from "../../utils/enum";

interface IProps {
  setError?: (error: string) => void;
}

interface Inputs {
  email: string;
  password: string;
}

const Login = ({ setError }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openSnackBar } = useSnackBar();
  const [login, { isLoading }] = useLoginMutation();
  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });

  const formSubmit: SubmitHandler<Inputs> = (data) => {
    const { email: identifier, password } = data;
    if (setError) {
      login({ identifier, password })
        .unwrap()
        .then((user) => {
          openSnackBar(t("Successfully logged in"), Severity.Success);
          dispatch(setCredentials(user));
          localStorage.setItem(Constant.User, JSON.stringify(user));
        })
        .then(() => navigate(ROUTE_MOVIES))
        .catch((err) => {
          if (isFetchBaseQueryError(err)) {
            const errMsg =
              Severity.Error in err ? err.error : JSON.stringify(err.data);
            setError(errMsg);
          } else if (isErrorWithMessage(err)) {
            setError(err.message);
          }
        });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        trigger().then(() =>
          handleSubmit(formSubmit)(e).catch(() =>
            openSnackBar(t("Something went wrong"), Severity.Error)
          )
        );
      }}
    >
      <Stack spacing={2}>
        <Controller
          name={"email"}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={fieldState.invalid}
              helperText={errors.email?.message}
              required
              fullWidth
              autoComplete={"email"}
              variant="standard"
              label={t("Email")}
            />
          )}
        />
        <Controller
          name={"password"}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={fieldState.invalid}
              helperText={errors.password?.message}
              required
              fullWidth
              type="password"
              autoComplete={"current-password"}
              variant="standard"
              label={t("Password")}
            />
          )}
        />
        <LoadingButton
          type={"submit"}
          disabled={false}
          loading={isLoading}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {t("Sign In")}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default Login;
