import { alpha, InputBase, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { searchMovies } from "../api/movies";
import { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../store/hooks";
import { setIsFiltering } from "../store/landing/landingSlice";
import { Color } from "../utils/enum";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

// TODO: Should use specific name related to the component. Ex: SearchFieldProps
interface SearchFieldProps {
  color?: Color.White | Color.Black;
}

const SearchField = ({ color }: SearchFieldProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const searchMutation = useMutation({
    mutationFn: searchMovies,
    onSuccess(data) {
      dispatch(setIsFiltering(true));
      queryClient.setQueryData(["movies"], data);
    },
  });

  useEffect(() => {
    if (debouncedSearchTerm !== "") {
      searchMutation.mutate(debouncedSearchTerm);
    }

    //eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon
          sx={{
            color,
          }}
        />
      </SearchIconWrapper>
      <StyledInputBase
        sx={{
          color,
        }}
        onChange={(event) => setSearchTerm(event.target.value)}
        placeholder={`${t("Search…")}`}
        inputProps={{ "aria-label": "search" }}
      />
    </Search>
  );
};

export default SearchField;
