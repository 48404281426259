import { useContext } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from "@mui/material";

import { DrawerContext } from "../../context/drawerContext";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_ACTORS,
  ROUTE_FAQ,
  ROUTE_FAVORITES,
  ROUTE_MANAGE_MOVIES,
  ROUTE_MOVIES,
  ROUTE_PHASES,
  ROUTE_REQUEST_MOVIE,
  ROUTE_SETTINGS,
} from "../../utils/route";
import Box from "@mui/material/Box";
import Drawer from "./Drawer";
import {
  Assignment,
  BorderAll,
  Help,
  Home,
  MonitorHeart,
  MovieFilter,
  People,
  Settings,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useSnackBar from "../../hooks/useSnackBar";
import { useQuery } from "@tanstack/react-query";
import { fetchUserRole } from "../../api/user";
import { Severity } from "../../utils/enum";

const Sidebar = () => {
  const navigate = useNavigate();
  const { width: drawerWidth } = useContext(DrawerContext);
  const { openSnackBar } = useSnackBar();
  const { t } = useTranslation();
  const { data, isError } = useQuery({
    queryKey: ["role"],
    refetchOnWindowFocus: false,
    queryFn: async () => await fetchUserRole(),
  });

  if (isError) {
    openSnackBar(t("Can't fetch user role"), Severity.Error);
  }

  return (
    <DrawerContext.Consumer>
      {({ isOpen, toggleDrawer }) => (
        <Drawer variant="permanent" open={isOpen} drawerWidth={drawerWidth}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <Button
              sx={{ ml: "auto", mr: "auto" }}
              variant="text"
              onClick={() => navigate(ROUTE_MOVIES)}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                FMOVIE
              </Typography>
            </Button>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <MenuList>
            <MenuItem onClick={() => navigate(ROUTE_MOVIES)}>
              <ListItemIcon>
                <Home fontSize="medium" />
              </ListItemIcon>
              <ListItemText>{t("Home")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate(ROUTE_PHASES)}>
              <ListItemIcon>
                <BorderAll fontSize="medium" />
              </ListItemIcon>
              <ListItemText>{t("Phases")}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigate(ROUTE_ACTORS)}>
              <ListItemIcon>
                <People fontSize="medium" />
              </ListItemIcon>
              <ListItemText>{t("Actors")}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigate(ROUTE_FAVORITES)}>
              <ListItemIcon>
                <MonitorHeart fontSize="medium" />
              </ListItemIcon>
              <ListItemText>{t("My favorites")}</ListItemText>
            </MenuItem>
            {data && data.type === "admin" ? (
              <MenuItem onClick={() => navigate(ROUTE_MANAGE_MOVIES)}>
                <ListItemIcon>
                  <MovieFilter fontSize="medium" />
                </ListItemIcon>
                <ListItemText>{t("Manage movies")}</ListItemText>
              </MenuItem>
            ) : null}
          </MenuList>
          <Box sx={{ flexGrow: 1 }}></Box>
          <MenuList>
            <MenuItem onClick={() => navigate(ROUTE_REQUEST_MOVIE)}>
              <ListItemIcon>
                <Assignment fontSize="medium" />
              </ListItemIcon>
              <ListItemText>{t("Request a movie")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => navigate(ROUTE_FAQ)}>
              <ListItemIcon>
                <Help fontSize="medium" />
              </ListItemIcon>
              <ListItemText>{t("FAQs")}</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => navigate(ROUTE_SETTINGS)}>
              <ListItemIcon>
                <Settings fontSize="medium" />
              </ListItemIcon>
              <ListItemText>{t("Settings")}</ListItemText>
            </MenuItem>
          </MenuList>
        </Drawer>
      )}
    </DrawerContext.Consumer>
  );
};

export default Sidebar;
