import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import { Direction } from "../../utils/enum";

interface StyledSmallCardProps extends BoxProps {
  direction: Direction.Vertical | Direction.Horizontal;
}

const StyledMovieCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "direction",
})<StyledSmallCardProps>(({ direction, theme }) => ({
  cursor: "pointer",
  display: "grid",
  placeItems: "center",
  ...(direction === Direction.Vertical && {
    width: 171,
    height: 96,
    [theme.breakpoints.up("sm")]: {
      width: 234,
      height: 132,
    },
    [theme.breakpoints.up("lg")]: {
      width: 321,
      height: 181,
    },
  }),
  ...(direction === Direction.Horizontal && {
    width: 86,
    height: 122,
    [theme.breakpoints.up("sm")]: {
      width: 117,
      height: 167,
    },
    [theme.breakpoints.up("md")]: {
      width: 200,
      height: 285,
    },
  }),
}));

export default StyledMovieCard;
