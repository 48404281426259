import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";

interface IActorProps {
  name: string;
  imageUrl: string;
  bio: string;
}

const ActorCard = ({ name, imageUrl, bio }: IActorProps) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar alt={name} src={imageUrl} />
      </ListItemAvatar>
      <ListItemText primary={name} secondary={bio} />
    </ListItem>
  );
};

export default ActorCard;
