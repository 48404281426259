import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ErrorBoundary from "./common/ErrorBoundary";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RequireAuth from "./components/auth/RequireAuth";
import ErrorPage from "./pages/ErrorPage";
import Dashboard from "./pages/Dashboard";
import Movies from "./components/movie/Movies";
import Movie from "./components/movie/Movie";
import LoginPage from "./pages/LoginPage";
import ProfilePage from "./pages/ProfilePage";
import {
  ROUTE_ACTORS,
  ROUTE_DASHBOARD,
  ROUTE_FAQ,
  ROUTE_FAVORITES,
  ROUTE_LOGIN,
  ROUTE_MANAGE_MOVIES,
  ROUTE_MOVIE,
  ROUTE_MOVIES,
  ROUTE_PHASE,
  ROUTE_PHASES,
  ROUTE_PROFILE,
  ROUTE_REQUEST_MOVIE,
  ROUTE_ROOT,
  ROUTE_SETTINGS,
} from "./utils/route";
import axios, { AxiosRequestConfig } from "axios";
import Phase from "./components/phase/Phase";
import Phases from "./components/phase/Phases";
import ActorsPage from "./pages/ActorsPage";
import RequestMoviePage from "./pages/RequestMoviePage";
import FAQPage from "./pages/FAQPage";
import SettingPage from "./pages/SettingPage";
import "./i18n";
import Favorites from "./components/manage/Favorites";
import ManageMovies from "./components/manage/ManageMovies";
import EditMoviePage from "./pages/EditMoviePage";
import i18n from "./i18n";
import LandingPage from "./pages/LandingPage";
import Home from "./components/landing/Home";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers !== undefined) {
    const token = store.getState().auth.jwt;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  config.params = {
    locale: i18n.language,
  };

  return config;
});

axios.defaults.params = {
  locale: i18n.language,
};

const router = createBrowserRouter([
  {
    path: "*",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTE_ROOT,
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <LandingPage />,
        children: [
          {
            path: "/",
            element: <Home />,
          },
          {
            path: `${ROUTE_MOVIE}/:slug`,
            element: <Movie />,
          },
          {
            path: ROUTE_LOGIN,
            element: <LoginPage />,
          },
        ],
      },
      {
        path: ROUTE_ROOT,
        element: <RequireAuth />,
        children: [
          {
            path: ROUTE_DASHBOARD,
            element: <Dashboard />,
            children: [
              {
                path: ROUTE_MOVIES,
                element: <Movies />,
              },
              {
                path: ROUTE_FAVORITES,
                element: <Favorites />,
              },
              {
                path: ROUTE_MANAGE_MOVIES,
                element: <ManageMovies />,
              },
              {
                path: `${ROUTE_MANAGE_MOVIES}/:id`,
                element: <EditMoviePage />,
              },
              {
                path: ROUTE_PHASES,
                element: <Phases />,
              },
              {
                path: `${ROUTE_PHASE}/:id`,
                element: <Phase />,
              },
              {
                path: `${ROUTE_MOVIES}/:slug`,
                element: <Movie />,
              },
              {
                path: ROUTE_PROFILE,
                element: <ProfilePage />,
              },
              {
                path: ROUTE_ACTORS,
                element: <ActorsPage />,
              },
              {
                path: ROUTE_REQUEST_MOVIE,
                element: <RequestMoviePage />,
              },
              {
                path: ROUTE_FAQ,
                element: <FAQPage />,
              },
              {
                path: ROUTE_SETTINGS,
                element: <SettingPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
