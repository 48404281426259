import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Stack, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { setCredentials } from "../../store/auth/authSlice";
import { useSignUpMutation } from "../../api/rtkQuery";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSnackBar from "../../hooks/useSnackBar";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpSchema } from "../../utils/formSchema";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "react-i18next";
import { Severity } from "../../utils/enum";
import { ROUTE_ROOT } from "../../utils/route";

interface IProps {
  setError: (error: string) => void;
}

interface Inputs {
  username: string;
  email: string;
  password: string;
  policy: boolean;
}

const SignUp = ({ setError }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openSnackBar } = useSnackBar();
  const [signUp, { isLoading }] = useSignUpMutation();
  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onChange",
    defaultValues: {
      username: "",
      email: "",
      password: "",
      policy: false,
    },
    resolver: yupResolver(signUpSchema),
  });

  const onSubmitClick = (): void => {
    trigger().then(() => handleSubmit(onSubmit));
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    signUp(data)
      .unwrap()
      .then((user) => {
        openSnackBar("Successfully signed up", Severity.Success);
        dispatch(setCredentials(user));
      })
      .then(() => navigate(ROUTE_ROOT))
      .catch(() => setError("An error has occurred"));
  };

  return (
    <form>
      <Stack spacing={2}>
        <Controller
          name={"username"}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={fieldState.invalid}
              helperText={errors.username?.message}
              required
              fullWidth
              autoComplete={"username"}
              variant="standard"
              label={t("Username")}
            />
          )}
        />
        <Controller
          name={"email"}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={fieldState.invalid}
              helperText={errors.email?.message}
              required
              fullWidth
              type="email"
              variant="standard"
              label={t("Email")}
            />
          )}
        />
        <Controller
          name={"password"}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              error={fieldState.invalid}
              helperText={errors.password?.message}
              required
              fullWidth
              type="password"
              autoComplete={"current-password"}
              variant="standard"
              label={t("Password")}
            />
          )}
        />
        <Controller
          name={"policy"}
          control={control}
          render={({ field, fieldState }) => (
            <FormControl
              required
              error={fieldState.invalid}
              component="fieldset"
              sx={{ m: 3 }}
              variant="standard"
            >
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label={t("Agree to terms and conditions")}
                />
              </FormGroup>
              <FormHelperText>{errors.policy?.message}</FormHelperText>
            </FormControl>
          )}
        />
        <LoadingButton
          onClick={onSubmitClick}
          loading={isLoading}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {t("Sign Up")}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default SignUp;
