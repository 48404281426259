import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Theme } from "../../utils/enum";

interface SettingState {
  mode: Theme.Light | Theme.Dark;
}

const initialState: SettingState = {
  mode: Theme.Dark,
};

export const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeMode(state) {
      state.mode = state.mode === Theme.Light ? Theme.Dark : Theme.Light;
    },
  },
});

export const { changeMode } = settingSlice.actions;

export const selectColorMode = (state: RootState): Theme.Light | Theme.Dark =>
  state.settings.mode;

export default settingSlice.reducer;
