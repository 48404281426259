import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { Box } from "@mui/material";
import React from "react";
import { useWindowSize } from "usehooks-ts";
import StyledBannerImage from "./StyledBannerImage";
import StyledBannerTitle from "./StyledBannerTitle";

const data = [
  {
    title: "Iron Man",
    image:
      "https://space-dmovie.sgp1.digitaloceanspaces.com/2a4ff83c50be3aa878ceb276db30f338.jpg",
  },
  {
    title: "Iron Man 2",
    image:
      "https://space-dmovie.sgp1.digitaloceanspaces.com/c0390e710aabbecaacf5cf80db47b1b5.jpg",
  },
  {
    title: "Iron Man 3",
    image:
      "https://space-dmovie.sgp1.digitaloceanspaces.com/79dd4b3b81c0e3056db06e96f89e1aa3.jpg",
  },
];

const BannerCarousel = () => {
  const { width } = useWindowSize();

  return (
    <Swiper
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination]}
      className="landingSwiper"
    >
      {data.map(({ image, title }, index) => (
        <SwiperSlide key={title}>
          <Box className="w-full h-full absolute bg-gradient-to-b from-transparent to-black"></Box>
          <StyledBannerImage className="object-cover" src={image} alt={title} />
          <StyledBannerTitle variant={width < 768 ? "h6" : "h1"} gutterBottom>
            {title}
          </StyledBannerTitle>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BannerCarousel;
