import { Box } from "@mui/material";
import BannerCarousel from "./BannerCarousel";
import StyledUnderBannerCarousel from "./StyledUnderBannerCarousel";
import MovieCarousel from "./MovieCarousel";
import FilterList from "./FilterList";
import { useSelector } from "react-redux";
import { selectIsFiltering } from "../../store/landing/landingSlice";
import { Direction } from "../../utils/enum";

const Home = () => {
  const isFiltering = useSelector(selectIsFiltering);

  return (
    <Box
      className="bg-black"
      sx={{
        minHeight: "calc(100vh - 416px)",
      }}
    >
      {!isFiltering ? (
        <>
          <BannerCarousel />
          <StyledUnderBannerCarousel className="w-full bg-black relative">
            <MovieCarousel
              direction={Direction.Horizontal}
              underBanner={true}
              title="Hot trend today"
            />
          </StyledUnderBannerCarousel>
          <Box className="w-full bg-black relative">
            <MovieCarousel
              direction={Direction.Vertical}
              title="Top movies of the week"
            />
          </Box>
          <Box className="w-full bg-black relative">
            <MovieCarousel
              direction={Direction.Vertical}
              title="Monthly movies"
            />
          </Box>
          <Box className="w-full bg-black relative">
            <MovieCarousel direction={Direction.Vertical} title="Best 2022" />
          </Box>
          <Box className="w-full bg-black relative">
            <MovieCarousel
              direction={Direction.Vertical}
              title="You might like this"
            />
          </Box>
        </>
      ) : (
        <FilterList />
      )}
    </Box>
  );
};

export default Home;
