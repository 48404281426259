import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { useQuery } from "@tanstack/react-query";
import { fetchActors } from "../api/actors";
import Loading from "../common/Loading";
import ActorCard from "../components/actor/ActorCard";
import { useTranslation } from "react-i18next";

const ActorsPage = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError, isFetching } = useQuery({
    queryKey: ["actors"],
    refetchOnWindowFocus: false,
    queryFn: async () => await fetchActors(),
  });

  if (isError) return <div>{t("An error has occurred!")}</div>;

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Box>
      <h1>{t("Actors page")}</h1>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data?.data.map((actor) => (
          <ActorCard
            name={actor.attributes.name}
            imageUrl={actor.attributes.avatar.data.attributes.url}
            bio={actor.attributes.bio}
            key={actor.attributes.name}
          />
        ))}
      </List>
    </Box>
  );
};

export default ActorsPage;
