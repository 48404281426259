import { Alert, Avatar, Paper, Stack, Typography } from "@mui/material";
import Loading from "../../common/Loading";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid";
import { YouTube } from "@mui/icons-material";
import { friendlyDuration } from "../../utils/time";
import Link from "@mui/material/Link";
import { Edit, Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ROUTE_MANAGE_MOVIES } from "../../utils/route";
import { fetchMovies } from "../../api/movies";
import { Severity } from "../../utils/enum";

const ManageMovies = () => {
  const { t } = useTranslation();
  // const { data, isLoading, isFetching, isError } = useGetMoviesQuery(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ["manageMovies"],
    refetchOnWindowFocus: false,
    queryFn: async () => await fetchMovies(),
  });

  const onEdit = (id: GridRowId): void => {
    navigate(`${ROUTE_MANAGE_MOVIES}/${id}`);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 50 },
    {
      field: "banner",
      headerName: "Poster",
      width: 70,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Avatar alt={params.value} src={params.value} />
      ),
    },
    { field: "title", headerName: "Title", width: 340, flex: 1 },
    {
      field: "releaseDate",
      headerName: "Release Date",
      width: 130,
      renderCell: (params: GridRenderCellParams) => (
        <Typography>
          {new Date(params.row.releaseDate).toLocaleDateString()}
        </Typography>
      ),
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 80,
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{friendlyDuration(params.row.duration)}</Typography>
      ),
    },
    {
      field: "trailer",
      headerName: "Trailer",
      width: 60,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          underline={"none"}
          color={"red"}
          href={params.row.trailer}
          target="_blank"
        >
          <YouTube fontSize="large">{t("View")}</YouTube>
        </Link>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction={"row"}>
          <Button variant="text" onClick={() => onEdit(params.id)}>
            <Edit fontSize={"medium"} />
          </Button>
          <Button
            variant="text"
            color={Severity.Error}
            onClick={() => onDelete(params.row.id)}
          >
            <Delete fontSize={"medium"} />
          </Button>
        </Stack>
      ),
    },
  ];

  if (isError) return <div>{t("An error has occurred!")}</div>;

  if (isLoading || isFetching) {
    return <Loading />;

    // test the error boundary
    // throw new Error("Crashed!");
  }

  const onDelete = (id: number): void => {
    const removedData = data?.data.filter((movie) => movie.id !== id);
    queryClient.setQueryData(["manageMovies"], { data: removedData });
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        {t("Manage movies")}
      </Typography>
      <Paper sx={{ width: "100%" }}>
        {data !== undefined ? (
          <DataGrid
            autoHeight
            rows={data.data.map((movie) => {
              return {
                id: movie.id,
                ...movie.attributes,
                banner:
                  movie.attributes.poster.data.attributes.formats.thumbnail
                    .url ?? "",
              };
            })}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        ) : (
          <Alert severity={Severity.Error}>
            {t("Favorites list is empty!")}
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default ManageMovies;
