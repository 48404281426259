import { Stack } from "@mui/material";
import Loading from "../../common/Loading";
import { useQuery } from "@tanstack/react-query";
import { fetchMovies } from "../../api/movies";
import { useTranslation } from "react-i18next";
import { Swiper } from "swiper/react";
import { Pagination } from "swiper";
import MovieList from "./MovieList";
import MoviesSlider from "./MoviesSlider";
import { useWindowSize } from "usehooks-ts";

import "swiper/css";
import "swiper/css/pagination";

const Movies = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  // const { data, isLoading, isFetching, isError } = useGetMoviesQuery(null);
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ["movies"],
    refetchOnWindowFocus: false,
    queryFn: async () => await fetchMovies(),
  });

  if (isError) return <div>{t("An error has occurred!")}</div>;

  if (isLoading || isFetching) {
    return <Loading />;

    // test the error boundary
    // throw new Error("Crashed!");
  }

  return (
    <Stack spacing={4}>
      <Swiper pagination={true} modules={[Pagination]}>
        {data?.data.length >= 3
          ? MoviesSlider({ data: data.data, width })
          : null}
      </Swiper>
      <MovieList data={data.data} />
    </Stack>
  );
};

export default Movies;
