import Box from "@mui/material/Box";
import { useQuery } from "@tanstack/react-query";
import { fetchPhases } from "../../api/phases";
import Loading from "../../common/Loading";
import PhaseCard from "./PhaseCard";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const Phases = () => {
  const { t } = useTranslation();
  const { data, isLoading, isError, isFetching } = useQuery({
    queryKey: ["phases"],
    refetchOnWindowFocus: false,
    queryFn: async () => await fetchPhases(),
  });

  if (isError) return <div>{t("An error has occurred!")}</div>;

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Box>
      <h1>{t("Phases list")}</h1>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {data?.data.map((phase) => {
          return (
            <Grid key={phase.id} item xs={12} sm={6}>
              <PhaseCard
                id={phase.id}
                imageUrl={phase.attributes.banner.data.attributes.url}
                name={phase.attributes.name}
                description={phase.attributes.description ?? ""}
                key={phase.id}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Phases;
