import { Typography } from "@mui/material";
import { IMovie } from "../../store/movie/movieSlice";
import { SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

interface MoviesSliderProps {
  data: IMovie[];
  width: number;
}

const MoviesSlider = ({ data, width }: MoviesSliderProps): JSX.Element[] => {
  return data.slice(data.length - 4, data.length - 1).map((movie) => (
    <SwiperSlide key={movie.attributes.title}>
      <img
        src={movie.attributes.banner.data.attributes.url}
        alt={movie.attributes.title}
      />
      <Typography
        variant={width < 768 ? "h6" : "h4"}
        gutterBottom
        sx={{ position: "absolute", color: "white" }}
      >
        {movie.attributes.title}
      </Typography>
    </SwiperSlide>
  ));
};

export default MoviesSlider;
