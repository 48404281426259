import qs from "qs";
import { MovieResponse, MoviesResponse } from "../store/movie/movieSlice";
import axios from "axios";
import { EditMovieFormProps } from "../pages/EditMoviePage";
import { ROUTE_SLUGS } from "../utils/route";
import { Entity } from "../utils/enum";

interface IFilterAttribute {
  [key: string]: string | number | boolean | object;
}

interface IFilterAttributes {
  [key: string]: IFilterAttribute;
}

export interface IFilterProps {
  filters: IFilterAttributes;
  sort?: string | string[];
}

export const fetchMovies = async (): Promise<MoviesResponse> => {
  const query = qs.stringify(
    {
      populate: ["banner", "poster"],
      sort: "timeline:asc",
    },
    {
      encodeValuesOnly: true,
    }
  );

  return await axios
    .get<MoviesResponse>(
      `${process.env.REACT_APP_API_URL}/${Entity.Movies}?${query}`
    )
    .then((res) => res.data);
};

export const fetchSlugMovie = async (slug: string): Promise<MovieResponse> => {
  const query = qs.stringify(
    {
      fields: [
        "id",
        "title",
        "description",
        "imdbId",
        "releaseDate",
        "trailer",
        "duration",
        "timeline",
        "playerUrl",
      ],
      populate: ["banner"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  return await axios
    .get<MovieResponse>(
      `${process.env.REACT_APP_API_URL}/${ROUTE_SLUGS}/${Entity.Movie}/${slug}?${query}`
    )
    .then((res) => res.data);
};

export const filterMovies = async (
  props: IFilterProps | null
): Promise<MoviesResponse> => {
  const filters = props?.filters !== null ? { filters: props?.filters } : {};
  const query = qs.stringify(
    {
      populate: ["banner", "poster"],
      ...filters,
      sort: "timeline:asc",
    },
    {
      encodeValuesOnly: true,
    }
  );

  return await axios
    .get<MoviesResponse>(`${process.env.REACT_APP_API_URL}/movies?${query}`)
    .then((res) => res.data);
};

export const searchMovies = async (search: string): Promise<MoviesResponse> => {
  const query = qs.stringify(
    {
      populate: ["banner", "poster"],
      filters: {
        title: {
          $containsi: search,
        },
      },
      locale: ["en", "vi"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  return await axios
    .get<MoviesResponse>(`${process.env.REACT_APP_API_URL}/movies?${query}`)
    .then((res) => res.data);
};

export const fetchMovie = async (id: string): Promise<MovieResponse> => {
  const query = qs.stringify(
    {
      populate: ["banner", "actors", "phase"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  return await axios
    .get<MovieResponse>(
      `${process.env.REACT_APP_API_URL}/movies/${id}?${query}`
    )
    .then((res) => res.data);
};

export const updateMovie = async (
  props: EditMovieFormProps
): Promise<MovieResponse> => {
  const { id } = props;
  delete props.id;
  return await axios
    .put<MovieResponse>(`${process.env.REACT_APP_API_URL}/movies/${id}`, {
      data: props,
    })
    .then((res) => res.data);
};
