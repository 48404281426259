export const enum Color {
  White = "white",
  Black = "black",
}

export const enum Direction {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

export const enum Severity {
  Error = "error",
  Warning = "warning",
  Info = "info",
  Success = "success",
}

export const enum Constant {
  User = "user",
}

export const enum Theme {
  Light = "light",
  Dark = "dark",
}

export const enum Entity {
  Movie = "movie",
  Actor = "actor",
  Phase = "phase",
  User = "user",
  Movies = "movies",
  Actors = "actors",
  Phases = "phases",
  Users = "users",
}
