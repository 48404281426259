import { useNavigate, useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { Alert, Button } from "@mui/material";
import { ROUTE_MOVIES } from "../utils/route";
import { Severity } from "utils/enum";

interface RouteError {
  statusText: string;
  message: string;
}

const ErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const error = useRouteError() as RouteError;

  return (
    <Box
      id="error-page"
      sx={{
        width: "100vw",
        height: "100vh",
        display: "grid",
        placeItems: "center",
      }}
    >
      <h1>Oops!</h1>
      <Alert severity={Severity.Error}>
        {t("Sorry, an unexpected error has occurred.")}
      </Alert>
      <Button onClick={() => navigate(ROUTE_MOVIES)} variant="contained">
        {t("Back to home")}
      </Button>
      <p>
        <i>{error.statusText !== "" || error.message}</i>
      </p>
    </Box>
  );
};

export default ErrorPage;
