import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";

interface StyledBoxProps extends BoxProps {
  underBanner?: boolean;
}

const StyledCarousel = styled(Box, {
  shouldForwardProp: (prop) => prop !== "underBanner",
})<StyledBoxProps>(({ underBanner, theme }) => ({
  position: "relative",
  paddingLeft: "1rem",
  paddingTop: "1rem",
  ...(underBanner && {
    top: "-320px",
    position: "absolute",
    marginBottom: 118,
    paddingTop: "20rem",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "3rem",
    },
  }),
  ...(!underBanner && {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "3rem",
      paddingTop: "5rem",
    },
  }),
}));

export default StyledCarousel;
