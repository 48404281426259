import { Stack } from "@mui/material";
import { IPhase } from "../store/movie/phaseSlice";
import Loading from "./Loading";
import { NavLink } from "react-router-dom";
import { ROUTE_MOVIES, ROUTE_PHASE } from "../utils/route";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

interface IPhaseListProps {
  isLoading: boolean;
  phases: IPhase[];
}

export const activeClassName = "nav-item-active";
export const navLinkClassName = "nav-item";

const PhaseList = ({ isLoading, phases }: IPhaseListProps) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // TODO: Use !!anchorEl
  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (width < 768) {
    return (
      <>
        <Button
          id="phases-list"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {t("Phases")}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            {" "}
            <NavLink
              to={ROUTE_MOVIES}
              key="all"
              className={({ isActive }) =>
                isActive ? activeClassName : navLinkClassName
              }
            >
              {t("All timeline")}
            </NavLink>
          </MenuItem>
          {phases.map((phase) => (
            <MenuItem onClick={handleClose} key={phase.id}>
              <NavLink
                to={`${ROUTE_PHASE}/${phase.phase}`}
                key={phase.phase}
                className={({ isActive }) =>
                  isActive ? activeClassName : navLinkClassName
                }
              >
                {t("Phase")} {phase.phase}
              </NavLink>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }

  return (
    <Stack direction="row" spacing={2}>
      <NavLink
        to={ROUTE_MOVIES}
        key="all"
        className={({ isActive }) =>
          isActive ? activeClassName : navLinkClassName
        }
      >
        {t("All timeline")}
      </NavLink>
      {phases.map((phase) => (
        <NavLink
          to={`${ROUTE_PHASE}/${phase.phase}`}
          key={phase.phase}
          className={({ isActive }) =>
            isActive ? activeClassName : navLinkClassName
          }
        >
          {t("Phase")} {phase.phase}
        </NavLink>
      ))}
    </Stack>
  );
};

export default PhaseList;
