import axios from "axios";
import { Role, User } from "../store/auth/authSlice";
import qs from "qs";
import { Entity } from "../utils/enum";

export const fetchUserRole = async (): Promise<Role | undefined> => {
  const query = qs.stringify(
    {
      populate: ["role"],
    },
    {
      encodeValuesOnly: true,
    }
  );

  return axios
    .get<User | undefined>(
      `${process.env.REACT_APP_API_URL}/${Entity.Users}/me?${query}`
    )
    .then((res) => res.data?.role);
};
