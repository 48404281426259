import * as yup from "yup";
import { AnyObjectSchema } from "yup";

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const loginSchema: AnyObjectSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Please input a valid email!")
      .required("Email is required!"),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  })
  .required();

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const signUpSchema: AnyObjectSchema = yup
  .object()
  .shape({
    username: yup.string().required("Username is required!"),
    email: yup
      .string()
      .email("Please input a valid email!")
      .required("Email is required!"),
    password: yup
      .string()
      .required("No password provided.")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    policy: yup.boolean().required().oneOf([true], "Please accept the terms!"),
  })
  .required();

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const profileSchema: AnyObjectSchema = yup
  .object()
  .shape({
    username: yup.string().required("Username is required!"),
    email: yup
      .string()
      .email("Please input a valid email!")
      .required("Email is required!"),
    gender: yup.string(),
  })
  .required();

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const requestMovieSchema: AnyObjectSchema = yup
  .object()
  .shape({
    firstName: yup.string().required("First name is required!"),
    lastName: yup.string().required("Last name is required!"),
    email: yup
      .string()
      .email("Please input a valid email!")
      .required("Email is required!"),
    title: yup.string().required("No title of the movie provided."),
    link: yup.string(),
    request: yup.string(),
    policy: yup.boolean().oneOf([true], "Please verify the recaptcha!"),
  })
  .required();

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const movieSchema: AnyObjectSchema = yup
  .object()
  .shape({
    title: yup.string().required("No title of the movie provided."),
    description: yup.string(),
    playerUrl: yup.string().required("No title of the movie provided."),
    imdbId: yup.string(),
    releaseDate: yup.string(),
    trailer: yup.string(),
    duration: yup.string().required("No title of the movie provided."),
  })
  .required();
