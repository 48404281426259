import { Box, IconButton, Menu, Tooltip } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import React, { MouseEvent, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { ROUTE_LOGIN, ROUTE_MOVIES, ROUTE_PROFILE } from "../utils/route";
import { useTranslation } from "react-i18next";
import useSnackBar from "../hooks/useSnackBar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearCredentials } from "../store/auth/authSlice";
import SearchField from "./SearchField";
import useAuth from "../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import { fetchUserRole } from "../api/user";
import { Color, Constant, Severity } from "../utils/enum";

const ProfileMenu = () => {
  const { t } = useTranslation();
  const { openSnackBar } = useSnackBar();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = !!anchorEl;

  const { data } = useQuery({
    queryKey: ["role"],
    refetchOnWindowFocus: false,
    queryFn: async () => await fetchUserRole(),
  });

  const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = (): void => {
    openSnackBar(t("Logged out successfully"), Severity.Success);
    dispatch(clearCredentials());
    localStorage.removeItem(Constant.User);
    setAnchorEl(null);
  };

  const menuItems = (): JSX.Element | JSX.Element[] => {
    if (user) {
      const items = [
        <MenuItem key="profile" onClick={() => navigate(ROUTE_PROFILE)}>
          {t("Profile")}
        </MenuItem>,
        <MenuItem key={"logout"} onClick={handleLogout}>
          {t("Log out")}
        </MenuItem>,
      ];

      if (data?.type === "admin") {
        items.push(
          <MenuItem key="dashboard" onClick={() => navigate(ROUTE_MOVIES)}>
            {t("Dashboard")}
          </MenuItem>
        );

        return items;
      }
    }

    return (
      <MenuItem key={"login"} onClick={() => navigate(`/${ROUTE_LOGIN}`)}>
        {t("Log in")}
      </MenuItem>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ flexGrow: 1 }} />
      <SearchField color={Color.White} />
      <Tooltip title={t("Open settings")}>
        <IconButton
          onClick={handleProfileMenuOpen}
          color="inherit"
          size="large"
        >
          <AccountCircle
            fontSize="large"
            sx={{
              color: "white",
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id={"user-setting"}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {menuItems()}
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
