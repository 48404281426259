import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counter/counterSlice";
import authReducer from "./auth/authSlice";
import snackBarReducer from "./snackbar/snackBarSlice";
import movieReducer from "./movie/movieSlice";
import phaseReducer from "./movie/phaseSlice";
import landingReducer from "./landing/landingSlice";
import settingReducer from "./settings/settingSlice";
import { api } from "../api/rtkQuery";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    snackBar: snackBarReducer,
    movie: movieReducer,
    phase: phaseReducer,
    landing: landingReducer,
    settings: settingReducer,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware().concat(api.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
