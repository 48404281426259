import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchMovie, updateMovie } from "../api/movies";
import Loading from "../common/Loading";
import { useTranslation } from "react-i18next";
import { Paper, Stack, TextField } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import useSnackBar from "../hooks/useSnackBar";
import { yupResolver } from "@hookform/resolvers/yup";
import { movieSchema } from "../utils/formSchema";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useEffect } from "react";
import dayjs from "dayjs";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { fetchActors } from "../api/actors";
import { fetchPhases } from "../api/phases";
import { pick } from "lodash";
import { Severity } from "../utils/enum";

export interface EditMovieFormProps {
  id?: number;
  title: string;
  description: string;
  playerUrl: string;
  imdbId: string;
  releaseDate: string;
  trailer: string;
  duration: string;
  timeline: number;
  actors?: number[];
  phase?: number;
}

const formKeys = [
  "title",
  "description",
  "playerUrl",
  "imdbId",
  "releaseDate",
  "trailer",
  "duration",
  "timeline",
];

const EditMoviePage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ["movie", id],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      if (id) {
        return await fetchMovie(id);
      }
    },
  });
  const { data: actors } = useQuery({
    queryKey: ["actors"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      return await fetchActors();
    },
  });
  const { data: phases } = useQuery({
    queryKey: ["phases"],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      return await fetchPhases();
    },
  });
  const { mutate, isLoading: updating } = useMutation({
    mutationFn: updateMovie,
    onSuccess: () => {
      openSnackBar(t("Movie updated successfully!"), Severity.Success);
    },
  });

  const { openSnackBar } = useSnackBar();
  const {
    handleSubmit,
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<EditMovieFormProps>({
    mode: "onChange",
    defaultValues: {
      ...pick(data?.data.attributes, formKeys),
      actors:
        data?.data.attributes?.actors?.data.map((actor) => actor.id) ?? [],
      phase: data?.data.attributes?.phase?.data.id ?? 0,
      id: data?.data.id ?? 0,
    },
    resolver: yupResolver(movieSchema),
  });

  const formSubmit: SubmitHandler<EditMovieFormProps> = (data) => {
    const duration = dayjs(data.duration).format("HH:mm:ss.SSS");
    mutate({ ...data, duration });
  };

  useEffect(() => {
    if (data) {
      const duration = (): string => {
        try {
          const duration = data.data.attributes.duration.split(":");
          return dayjs()
            .hour(Number(duration[0]))
            .minute(Number(duration[1]))
            .millisecond(0)
            .toISOString();
        } catch (e) {
          return dayjs().hour(0).minute(0).toISOString();
        }
      };
      setValue("id", data.data.id);
      setValue("title", data.data.attributes.title);
      setValue("description", data.data.attributes.description);
      setValue("releaseDate", data.data.attributes.releaseDate);
      setValue("duration", duration());
      setValue("playerUrl", data.data.attributes.playerUrl);
      setValue("trailer", data.data.attributes.trailer);
      setValue("imdbId", data.data.attributes.imdbId);
      setValue(
        "actors",
        data?.data.attributes?.actors?.data.map((actor) => actor.id) ?? []
      );
      setValue("phase", data?.data.attributes?.phase?.data.id ?? 0);
    }
  }, [data, setValue]);

  if (isError) return <div>{t("An error has occurred!")}</div>;

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return (
    <Paper sx={{ p: 4 }}>
      <h1>Edit movie: {data?.data.attributes.title}</h1>
      <form
        onSubmit={(e) => {
          trigger().then(() =>
            handleSubmit(formSubmit)(e).catch(() =>
              openSnackBar(t("Something went wrong"), Severity.Error)
            )
          );
        }}
      >
        <Stack spacing={2}>
          <Controller
            name={"title"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={fieldState.invalid}
                helperText={errors.title?.message}
                required
                fullWidth
                autoComplete={"title"}
                variant="standard"
                label={t("Title")}
              />
            )}
          />
          <Controller
            name={"description"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={fieldState.invalid}
                helperText={errors.title?.message}
                fullWidth
                multiline
                maxRows={4}
                autoComplete={"description"}
                variant="standard"
                label={t("Description")}
              />
            )}
          />
          <Controller
            name={"playerUrl"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={fieldState.invalid}
                helperText={errors.title?.message}
                required
                fullWidth
                autoComplete={"playerUrl"}
                variant="standard"
                label={t("Player URL")}
              />
            )}
          />
          <Controller
            name={"imdbId"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={fieldState.invalid}
                helperText={errors.imdbId?.message}
                fullWidth
                autoComplete={"imdbId"}
                variant="standard"
                label={t("IMDB ID")}
              />
            )}
          />
          <Controller
            name={"releaseDate"}
            control={control}
            render={({ field, fieldState }) => (
              <DatePicker
                label={t("Release Date")}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={fieldState.invalid}
                    helperText={errors.releaseDate?.message}
                    autoComplete={"releaseDate"}
                    variant="standard"
                    label={t("Release Date")}
                  />
                )}
              />
            )}
          />
          <Controller
            name={"trailer"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={fieldState.invalid}
                helperText={errors.releaseDate?.message}
                fullWidth
                autoComplete={"trailer"}
                variant="standard"
                label={t("Trailer")}
              />
            )}
          />
          <Controller
            name={"duration"}
            control={control}
            render={({ field, fieldState }) => (
              <TimePicker
                ampm={false}
                label={t("Duration")}
                value={field.value}
                onChange={(value) => field.onChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={fieldState.invalid}
                    helperText={errors.duration?.message}
                    autoComplete={"duration"}
                    variant="standard"
                    label={t("Duration")}
                  />
                )}
              />
            )}
          />
          <Controller
            name={"actors"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel id="actors-select-label">{t("Actors")}</InputLabel>
                <Select
                  labelId="actors-select-label"
                  id="actors-select"
                  value={field.value}
                  multiple
                  label={t("Actors")}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    setValue(
                      "actors",
                      typeof value === "string"
                        ? value.split(",").map((item) => Number(item))
                        : value
                    );
                  }}
                >
                  {actors?.data.map((actor) => (
                    <MenuItem key={actor.attributes.name} value={actor.id}>
                      {actor.attributes.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name={"phase"}
            control={control}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <InputLabel id="phase-select-label">{t("Phase")}</InputLabel>
                <Select
                  labelId="phase-select-label"
                  id="phase-select"
                  value={field.value}
                  label={t("Phase")}
                  onChange={(event) => {
                    field.onChange(event.target.value as string);
                  }}
                >
                  {phases?.data.map((phase) => (
                    <MenuItem value={phase.id}>
                      {phase.attributes.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <LoadingButton
            type={"submit"}
            disabled={false}
            loading={updating}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("Save")}
          </LoadingButton>
        </Stack>
      </form>
    </Paper>
  );
};

export default EditMoviePage;
