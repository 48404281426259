import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  SignInRequest,
  SignUpRequest,
  User,
  UserResponse,
} from "../store/auth/authSlice";
import { MovieResponse, MoviesResponse } from "../store/movie/movieSlice";
import qs from "qs";
import { RootState } from "../store/store";
import { IPhase, PhaseResponse } from "../store/movie/phaseSlice";
import { Entity } from "../utils/enum";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
    prepareHeaders(headers, { getState }) {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.jwt;
      if (token != null) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, SignInRequest>({
      query: (credentials) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/local`,
        method: "POST",
        body: credentials,
      }),
    }),
    signUp: builder.mutation<UserResponse, SignUpRequest>({
      query: (credentials) => ({
        url: `${process.env.REACT_APP_API_URL}/auth/local/register`,
        method: "POST",
        body: credentials,
      }),
    }),
    updateProfile: builder.mutation<User, User>({
      query: (user) => ({
        url: `${process.env.REACT_APP_API_URL}/${Entity.Users}/${user?.id}`,
        method: "PUT",
        body: user,
      }),
    }),
    getMovies: builder.query<MoviesResponse, null>({
      query() {
        const query = qs.stringify(
          {
            populate: ["banner"],
          },
          {
            encodeValuesOnly: true,
          }
        );
        return {
          url: `${process.env.REACT_APP_API_URL}/${Entity.Movies}?${query}`,
          method: "GET",
        };
      },
    }),
    getMovie: builder.query<MovieResponse, string>({
      query(id) {
        const query = qs.stringify(
          {
            fields: [
              "id",
              "title",
              "description",
              "imdbId",
              "releaseDate",
              "trailer",
              "duration",
              "timeline",
              "playerUrl",
            ],
            populate: {
              banner: {
                fields: ["url"],
              },
            },
          },
          {
            encodeValuesOnly: true,
          }
        );
        return {
          url: `${process.env.REACT_APP_API_URL}/movies/${id}?${query}`,
          method: "GET",
        };
      },
    }),
    getPhases: builder.query<IPhase[], null>({
      query() {
        return {
          url: `${process.env.REACT_APP_API_URL}/phases/`,
          method: "GET",
        };
      },
      transformResponse(response: PhaseResponse) {
        return response.data.map((phase) => ({
          id: phase.id,
          name: phase.attributes.name,
          phase: phase.attributes.number,
        }));
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useGetMoviesQuery,
  useGetMovieQuery,
  useGetPhasesQuery,
  useUpdateProfileMutation,
} = api;
