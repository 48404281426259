import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SubmitHandler, useForm } from "react-hook-form";
import RequestMovieStepOne from "./RequestMovieStepOne";
import useSnackBar from "../../hooks/useSnackBar";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { requestMovieSchema } from "../../utils/formSchema";
import { useMutation } from "@tanstack/react-query";
import { requestMovie } from "../../api/requestMovie";
import { RequestMovieInputs } from "../../pages/RequestMoviePage";
import LoadingButton from "@mui/lab/LoadingButton";
import RequestMovieStepTwo from "./RequestMovieStepTwo";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";
import { Severity } from "../../utils/enum";

const steps = ["Input your information", "Input movie information", "Done"];

const RequestMovieSteps = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [activeStep, setActiveStep] = React.useState(0);
  const { openSnackBar } = useSnackBar();
  const {
    handleSubmit,
    control,
    reset,
    trigger,
    formState: { errors },
  } = useForm<RequestMovieInputs>({
    mode: "onSubmit",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      title: "",
      link: "",
      request: "",
      policy: false,
    },
    resolver: yupResolver(requestMovieSchema),
  });
  const { mutate, isLoading, isError } = useMutation({
    mutationFn: requestMovie,
    onSuccess: () => {
      openSnackBar(t("Request sent successfully!"), Severity.Success);
    },
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    reset();
    setActiveStep(0);
  };

  if (isError) {
    openSnackBar(t("Request failed"), Severity.Error);
  }

  const onSubmit: SubmitHandler<RequestMovieInputs> = (data) => {
    mutate(data);
  };

  const renderButton = () => {
    if (activeStep === 1 && activeStep !== steps.length - 1) {
      return (
        <LoadingButton
          type={"submit"}
          loading={isLoading}
          variant="contained"
          sx={{ mr: 1 }}
        >
          {t("Submit request")}
        </LoadingButton>
      );
    }

    if (activeStep === steps.length - 1) {
      return (
        <Button onClick={handleReset}>{t("Request another movie")}</Button>
      );
    }

    return <Button onClick={handleNext}>Next</Button>;
  };

  const renderStep = () => {
    switch (activeStep) {
      case 0:
        return <RequestMovieStepOne control={control} errors={errors} />;
      case 1:
        return <RequestMovieStepTwo control={control} errors={errors} />;
      case 2:
        return (
          <Box>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {t("All steps completed - you are finished")}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
            </Box>
          </Box>
        );
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        activeStep={activeStep}
        orientation={width < 768 ? "vertical" : "horizontal"}
      >
        {steps.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box sx={{ mt: 2 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            trigger().then(() =>
              handleSubmit(onSubmit)(e)
                .then(() => {
                  handleNext();
                })
                .catch(() =>
                  openSnackBar(t("Something went wrong"), Severity.Error)
                )
            );
          }}
        >
          {renderStep()}
          <Box sx={{ display: "flex", flexDirection: "row", mt: 4 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              {t("Back")}
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {renderButton()}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default RequestMovieSteps;
