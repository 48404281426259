import { IMovie } from "../../store/movie/movieSlice";
import { useNavigate } from "react-router-dom";
import { ROUTE_MOVIE } from "../../utils/route";
import StyledMovieCard from "../landing/StyledSmallCard";
import StyledSmallImage from "../landing/StyledSmallImage";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useState } from "react";
import { Direction } from "../../utils/enum";

interface MovieCardProps {
  data: IMovie;
  direction: Direction.Horizontal | Direction.Vertical;
}

const SmallMovieCard = ({ data, direction }: MovieCardProps) => {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);

  const url =
    data.attributes[direction === "horizontal" ? "poster" : "banner"].data
      .attributes.url;

  const handleClick = (slug: string): void => {
    navigate(`/${ROUTE_MOVIE}/${slug}`);
  };

  return (
    <StyledMovieCard
      direction={direction}
      key={data.attributes.title}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => handleClick(data.attributes.slug)}
    >
      <StyledSmallImage
        className={"movie-banner rounded-sm"}
        src={`${url}?w=248&fit=crop&auto=format`}
        srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
        alt={data.attributes.title}
        loading="lazy"
      />
      <Box
        sx={[
          {
            placeItems: "center",
            position: "absolute",
            zIndex: 10,
            display: "none",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          isHovering && {
            display: "grid",
          },
        ]}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={[
            {
              color: "white",
            },
          ]}
        >
          {data.attributes.title}
        </Typography>
      </Box>
    </StyledMovieCard>
  );
};

export default SmallMovieCard;
