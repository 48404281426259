import Box from "@mui/material/Box";
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useAuth from "../hooks/useAuth";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { AuthState, setCredentials, User } from "../store/auth/authSlice";
import useSnackBar from "../hooks/useSnackBar";
import { useUpdateProfileMutation } from "../api/rtkQuery";
import { useState } from "react";
import { useAppDispatch } from "../store/hooks";
import { isErrorWithMessage, isFetchBaseQueryError } from "../services/helper";
import { ROUTE_PROFILE } from "../utils/route";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileSchema } from "../utils/formSchema";
import { useTranslation } from "react-i18next";
import { Constant, Severity } from "../utils/enum";

const ProfilePage = () => {
  const { user, jwt } = useAuth();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openSnackBar } = useSnackBar();
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();
  const [isEditing, setIsEditing] = useState(false);
  const { handleSubmit, control, reset } = useForm<User>({
    mode: "onChange",
    defaultValues: {
      username: user?.username ?? "",
      email: user?.email ?? "",
      gender: user?.gender ?? "",
    },
    resolver: yupResolver(profileSchema),
  });

  const onReset = (): void => {
    setIsEditing(false);
    reset();
  };

  const formSubmit: SubmitHandler<User> = (data) => {
    if (!user || !user.id) {
      return;
    }

    updateProfile({ ...data, id: user.id })
      .unwrap()
      .then((_user) => {
        const newUser: AuthState = { user: _user, jwt };
        openSnackBar(t("Update profile successfully"), Severity.Success);
        dispatch(setCredentials(newUser));
        localStorage.setItem(Constant.User, JSON.stringify(newUser));
        navigate(ROUTE_PROFILE);
      })
      .catch((err) => {
        if (isFetchBaseQueryError(err)) {
          const errMsg =
            Severity.Error in err ? err.error : JSON.stringify(err.data);
          openSnackBar(errMsg, Severity.Error);
        } else if (isErrorWithMessage(err)) {
          openSnackBar(err.message, Severity.Error);
        }
      })
      .finally(() => setIsEditing(false));
  };

  return (
    <Box
      maxWidth={400}
      alignContent={"center"}
      marginLeft={"auto"}
      marginRight={"auto"}
    >
      <Typography variant="h2" gutterBottom>
        {t("Profile")}
      </Typography>
      <Paper sx={{ p: 4 }}>
        <form
          onSubmit={(e) => {
            handleSubmit(formSubmit)(e).catch(() =>
              openSnackBar(t("Something went wrong"), Severity.Error)
            );
          }}
        >
          <Stack spacing={2}>
            <Controller
              name={"username"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled={!isEditing}
                  required
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  autoComplete={"username"}
                  variant="standard"
                  label={t("Username")}
                />
              )}
            />
            <Controller
              name={"email"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled={!isEditing}
                  required
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  autoComplete={"email"}
                  variant="standard"
                  label={t("Email")}
                />
              )}
            />
            <Controller
              name={"gender"}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  disabled={!isEditing}
                  labelId="gender"
                  id="gender"
                  label={t("Gender")}
                >
                  <MenuItem value="male">{t("Male")}</MenuItem>
                  <MenuItem value="female">{t("Female")}</MenuItem>
                </Select>
              )}
            />
            {!isEditing ? (
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => setIsEditing(true)}
              >
                {t("Edit")}
              </Button>
            ) : (
              <>
                <LoadingButton
                  type={"submit"}
                  disabled={false}
                  loading={isLoading}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t("Update")}
                </LoadingButton>
                <Button color={Severity.Error} onClick={() => onReset()}>
                  {t("Cancel")}
                </Button>
              </>
            )}
          </Stack>
        </form>
      </Paper>
    </Box>
  );
};

export default ProfilePage;
