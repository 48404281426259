import { Paper } from "@mui/material";
import RequestMovieSteps from "../components/request/RequestMovieSteps";

export interface RequestMovieInputs {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  link: string;
  request: string;
  policy: boolean;
}

const RequestMoviePage = () => {
  return (
    <Paper sx={{ p: 4 }}>
      <RequestMovieSteps />
    </Paper>
  );
};

export default RequestMoviePage;
