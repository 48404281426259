import { MovieAttributes } from "../store/movie/movieSlice";
import qs from "qs";
import axios from "axios";
import { User } from "../store/auth/authSlice";
import { IUpdateFavorites } from "../components/manage/Favorites";

export const fetchFavorites = async (): Promise<
  MovieAttributes[] | undefined
> => {
  const query = qs.stringify(
    {
      populate: {
        favorites: {
          populate: {
            banner: {
              fields: ["url"],
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return await axios
    .get<User>(`${process.env.REACT_APP_API_URL}/users/me?${query}`)
    .then((res) => {
      return res.data?.favorites;
    });
};

export const getFavorites = async (): Promise<MovieAttributes[]> => {
  return await axios
    .get<MovieAttributes[]>(`${process.env.REACT_APP_API_URL}/user/favorites`)
    .then((res) => {
      return res.data;
    });
};

export const updateFavorites = async ({
  userId,
  movieIds,
}: IUpdateFavorites): Promise<User> => {
  return await axios
    .put<User>(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
      favorites: movieIds,
    })
    .then((res) => {
      return res.data;
    });
};
