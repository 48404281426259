import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE_PHASE } from "../../utils/route";

interface IPhaseCardProps {
  id: number;
  imageUrl: string;
  name: string;
  description: string;
}
const PhaseCard = ({ id, imageUrl, description, name }: IPhaseCardProps) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{ height: "100%" }}
      onClick={() => navigate(`${ROUTE_PHASE}/${id}`)}
    >
      <CardActionArea>
        <CardMedia component="img" height={250} image={imageUrl} alt={name} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default PhaseCard;
