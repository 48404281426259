import React, { Context } from "react";

interface IDrawerState {
  width: number;
  isOpen: boolean;
  toggleDrawer: () => void;
}

export const drawerState = {
  width: 240,
  isOpen: true,
  toggleDrawer() {
    // do nothing
  },
};

export const DrawerContext: Context<IDrawerState> =
  React.createContext(drawerState);
