import { setSnackbar } from "../store/snackbar/snackBarSlice";
import { useAppDispatch } from "../store/hooks";
import { Severity } from "../utils/enum";

interface ISnackBar {
  openSnackBar: (message: string, severity: Severity) => void;
  closeSnackBar: () => void;
}

const useSnackBar = (): ISnackBar => {
  const dispatch = useAppDispatch();

  const openSnackBar = (message: string, severity: Severity): void => {
    dispatch(setSnackbar({ open: true, message, severity }));
  };

  const closeSnackBar = (): void => {
    dispatch(
      setSnackbar({ open: false, message: "", severity: Severity.Success })
    );
  };

  return { openSnackBar, closeSnackBar };
};

export default useSnackBar;
