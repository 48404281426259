import { IMovie } from "../../store/movie/movieSlice";
import { useNavigate } from "react-router-dom";
import { ROUTE_MOVIES } from "../../utils/route";
import { ImageListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Favorite } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getFavorites, updateFavorites } from "../../api/favorites";
import { IUpdateFavorites } from "../manage/Favorites";
import { isValueDefined } from "../../utils/typeguard";
import useAuth from "../../hooks/useAuth";

interface MovieCardProps {
  data: IMovie;
  maxWidth?: number;
}

const MovieCard = ({ data, maxWidth }: MovieCardProps) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isHovering, setIsHovering] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const { data: favorites } = useQuery({
    queryKey: ["favorites"],
    refetchOnWindowFocus: false,
    queryFn: async () => await getFavorites(),
  });
  const profileMutation = useMutation({
    mutationFn: updateFavorites,
  });
  const [updateData, setUpdateData] = useState<IUpdateFavorites>({
    userId: user?.id ?? 0,
    movieIds: [],
  });
  const url = data.attributes.poster.data.attributes.url;

  const handleFavorite = (state: boolean): void => {
    setIsFavorite(!isFavorite);

    if (!state) {
      const newData = {
        ...updateData,
        movieIds: [...updateData.movieIds, data.id],
      };

      setUpdateData(newData);
      profileMutation.mutate(newData);
    } else {
      const newData = {
        ...updateData,
        movieIds: updateData.movieIds.filter((id) => id !== data.id),
      };
      setUpdateData(newData);
      profileMutation.mutate(newData);
    }
  };

  const handleClick = (slug: string): void => {
    navigate(`${ROUTE_MOVIES}/${slug}`);
  };

  useEffect(() => {
    if (!favorites) {
      return;
    }

    setIsFavorite(favorites.some((favorite) => favorite.id === data.id));

    const movieIds = favorites.map((favorite) => favorite.id);
    const result = movieIds ? movieIds.filter(isValueDefined) : [];

    setUpdateData({
      userId: user?.id ?? 0,
      movieIds: result,
    });
  }, [data.id, favorites, user?.id]);

  return (
    <ImageListItem
      key={data.attributes.title}
      sx={{
        cursor: "pointer",
        display: "grid",
        placeItems: "center",
        maxWidth,
      }}
      onClick={() => handleClick(data.attributes.slug)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <img
        className={"movie-banner"}
        src={`${url}?w=248&fit=crop&auto=format`}
        srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
        alt={data.attributes.title}
        loading="lazy"
      />
      <Favorite
        onClick={() => handleFavorite(isFavorite)}
        fontSize="large"
        sx={[
          {
            color: isFavorite ? red[500] : "white",
            position: "absolute",
            top: 4,
            right: 4,
            zIndex: 1000,
            display: "none",
          },
          isHovering && {
            display: "block",
          },
        ]}
      />
      <Box
        sx={[
          {
            placeItems: "center",
            position: "absolute",
            zIndex: 999,
            display: "none",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          isHovering && {
            display: "grid",
          },
        ]}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={[
            {
              color: "white",
            },
          ]}
        >
          {data.attributes.title}
        </Typography>
      </Box>
    </ImageListItem>
  );
};

export default MovieCard;
