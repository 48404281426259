import { IMovie } from "../../store/movie/movieSlice";
import MovieCard from "./MovieCard";
import Masonry from "@mui/lab/Masonry";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Severity } from "../../utils/enum";

interface MovieListProps {
  data: IMovie[];
}

const MovieList = ({ data }: MovieListProps) => {
  const { t } = useTranslation();

  return (
    <Masonry
      columns={{ xs: 1, sm: 2, md: 3 }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
    >
      {data.length > 0 ? (
        data.map((movie) => (
          <MovieCard key={movie.attributes.title} data={movie} />
        ))
      ) : (
        <Alert severity={Severity.Error}>{t("Movie list is empty!")}</Alert>
      )}
    </Masonry>
  );
};

export default MovieList;
