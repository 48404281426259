import { Alert, Snackbar } from "@mui/material";
import { SyntheticEvent } from "react";
import { useSelector } from "react-redux";
import {
  selectSnackBarMessage,
  selectSnackBarOpen,
  selectSnackBarSeverity,
} from "../store/snackbar/snackBarSlice";
import useSnackBar from "../hooks/useSnackBar";

const GlobalSnackBar = () => {
  const { closeSnackBar } = useSnackBar();
  const open = useSelector(selectSnackBarOpen);
  const message = useSelector(selectSnackBarMessage);
  const severity = useSelector(selectSnackBarSeverity);

  const handleClose = (
    event?: SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === "clickaway") {
      return;
    }
    closeSnackBar();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default GlobalSnackBar;
