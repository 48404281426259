import { styled, Typography, TypographyProps } from "@mui/material";

const StyledBannerTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  position: "absolute",
  left: 16,
  bottom: 16,
  color: "white",
  fontSize: "3rem",
  fontWeight: 700,
  [theme.breakpoints.up("sm")]: {
    left: 30,
    bottom: 60,
    fontSize: "4rem",
  },
  [theme.breakpoints.up("md")]: {
    left: 60,
    bottom: 150,
    fontSize: "4rem",
  },
  [theme.breakpoints.up("lg")]: {
    left: 100,
    bottom: 400,
    fontSize: "10rem",
  },
}));

export default StyledBannerTitle;
