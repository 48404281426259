import Loading from "../../common/Loading";
import { useQuery } from "@tanstack/react-query";
import { filterMovies, IFilterProps } from "../../api/movies";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MovieList from "../movie/MovieList";

const Phase = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  // TODO: API was still called even user didn't log in
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ["phase", id],
    refetchOnWindowFocus: false,
    async queryFn() {
      const phaseProps: IFilterProps = {
        filters: {
          phase: {
            number: {
              $eq: id,
            },
          },
        },
      };
      return await filterMovies(phaseProps);
    },
  });

  if (isError) return <div>{t("An error has occurred!")}</div>;

  if (isLoading || isFetching) {
    return <Loading />;
  }

  return <MovieList data={data.data} />;
};

export default Phase;
