import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChangeEvent } from "react";
import useSnackBar from "../hooks/useSnackBar";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Typography from "@mui/material/Typography";
import { changeMode, selectColorMode } from "../store/settings/settingSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { Severity, Theme } from "../utils/enum";

const SettingPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { openSnackBar } = useSnackBar();
  const mode = useAppSelector(selectColorMode);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    i18n
      .changeLanguage((event.target as HTMLInputElement).value)
      .then(() => openSnackBar(t("Language changed!"), Severity.Success));
  };

  const onChangeMode = (): void => {
    dispatch(changeMode());
  };

  return (
    <Box>
      <Typography variant="h3" gutterBottom>
        {t("Setting page")}
      </Typography>
      <Paper sx={{ p: 4, display: "flex", justifyContent: "flex-start" }}>
        <FormControl>
          <FormLabel id="language-setting" sx={{ textAlign: "start" }}>
            {t("Language")}
          </FormLabel>
          <RadioGroup
            aria-labelledby="language-setting"
            defaultValue={i18n.language}
            name="language-radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel
              value="en"
              control={<Radio />}
              label={t("English")}
            />
            <FormControlLabel
              value="vi"
              control={<Radio />}
              label={t("Vietnamese")}
            />
          </RadioGroup>
        </FormControl>
      </Paper>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "background.default",
          color: "text.primary",
          borderRadius: 1,
          p: 3,
        }}
      >
        {mode} mode
        <IconButton sx={{ ml: 1 }} onClick={onChangeMode} color="inherit">
          {mode === Theme.Dark ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default SettingPage;
