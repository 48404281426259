import { useState, SyntheticEvent } from "react";
import { Alert, Tab, Tabs } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Login from "../components/auth/Login";
import SignUp from "../components/auth/SignUp";
import a11yProps from "../utils/allyProps";
import TabPanel from "../components/layout/TabPanel";
import { useTranslation } from "react-i18next";
import { Severity } from "../utils/enum";

const LoginPage = () => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        paddingTop: 10,
        minHeight: "calc(100vh - 416px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" sx={{ m: 1 }}>
          FMOVIE
        </Typography>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="Auth Tabs">
              <Tab
                sx={{ color: "white" }}
                label={t("Log in")}
                {...a11yProps(0)}
              />
              <Tab
                sx={{ color: "white" }}
                label={t("Sign Up")}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Login setError={setError} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SignUp setError={setError} />
          </TabPanel>
        </Box>
        {error !== "" && (
          <Alert sx={{ marginTop: 1 }} severity={Severity.Error}>
            {error}
          </Alert>
        )}
      </Box>
    </Container>
  );
};

export default LoginPage;
