import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AlertColor } from "@mui/material";
import { Severity } from "../../utils/enum";

export interface SnackbarState {
  open: boolean;
  message: string;
  severity: AlertColor;
}

const initialState: SnackbarState = {
  open: false,
  message: "",
  severity: Severity.Success,
};

const snackBarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackbar(state, action: PayloadAction<SnackbarState>) {
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
  },
});

export const { setSnackbar } = snackBarSlice.actions;

export default snackBarSlice.reducer;

export const selectSnackBarOpen = (state: RootState): boolean =>
  state.snackBar.open;

export const selectSnackBarMessage = (state: RootState): string =>
  state.snackBar.message;

export const selectSnackBarSeverity = (state: RootState): AlertColor =>
  state.snackBar.severity;
