import { useParams } from "react-router-dom";
import { Container, Paper, Stack, Typography } from "@mui/material";
import { Stream } from "@cloudflare/stream-react";
import Loading from "../../common/Loading";
import { useTranslation } from "react-i18next";
import { friendlyDuration } from "../../utils/time";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { uppercase } from "../../utils/text";
import { useQuery } from "@tanstack/react-query";
import { fetchSlugMovie } from "../../api/movies";
import RenderLink from "../../common/RenderLink";
import Box from "@mui/material/Box";

const information = [
  "description",
  "releaseDate",
  "trailer",
  "duration",
  "timeline",
  "imdb",
];

const Movie = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const { data, isError, isLoading, isFetching } = useQuery({
    queryKey: ["movie", slug],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      console.log(slug);
      if (slug) {
        return await fetchSlugMovie(slug);
      }
    },
  });

  if (isError) return <div>{t("An error has occurred!")}</div>;

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (!data) return <div>{t("Movie not found")}</div>;

  const { title, playerUrl } = data.data.attributes;
  const posterUrl = data.data.attributes.banner.data.attributes.url;

  const attributes = {
    ...data.data.attributes,
    duration: friendlyDuration(data.data.attributes.duration),
    imdb: `https://www.imdb.com/title/${data.data.attributes.imdbId}`,
  };

  return (
    <Box className="relative">
      <Box className="w-full h-fit absolute top-0 left-0">
        <img src={posterUrl} alt={title} className="w-full h-auto" />
      </Box>
      <Container maxWidth="lg" id="movie" className="py-20">
        <Typography
          className="text-inherit"
          variant="h3"
          gutterBottom
          align={"left"}
        >
          {title}
        </Typography>
        <Stream controls src={playerUrl} poster={posterUrl} />

        <Stack spacing={2} sx={{ mt: 4 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {Object.keys(attributes)
                  .filter((item) => {
                    return information.includes(item);
                  })
                  .map((key) => (
                    <TableRow
                      key={attributes[key]}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          borderBottom: 0,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align={"left"}
                        >
                          {t(uppercase(key))}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        className="border-0"
                        sx={{
                          borderBottom: 0,
                        }}
                      >
                        <RenderLink value={attributes[key].toString()} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
    </Box>
  );
};

export default Movie;
