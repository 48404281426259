/*
TODO: Should define full path here
EX: ROUTE_MOVIE = "/dashboard/movie";
*/

export const ROUTE_ROOT = "/";
export const ROUTE_LOGIN = "/login";

export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_MOVIES = "/dashboard/movies";
export const ROUTE_MOVIE = "/dashboard/movie";
export const ROUTE_FAVORITES = "/dashboard/favorites";
export const ROUTE_PROFILE = "/dashboard/profile";
export const ROUTE_PHASE = "/dashboard/phase";
export const ROUTE_PHASES = "/dashboard/phases";
export const ROUTE_ACTORS = "/dashboard/actors";
export const ROUTE_REQUEST_MOVIE = "/dashboard/request-movie";
export const ROUTE_FAQ = "/dashboard/faq";
export const ROUTE_SETTINGS = "/dashboard/settings";
export const ROUTE_MANAGE_MOVIES = "/dashboard/manage-movies";
export const ROUTE_SLUGS = "slugify/slugs";
